// =============================================================================
// Document
// =============================================================================

html {
  overflow: hidden;
  height: 100%;
  font-size: var(--basis);
}

body {
  height: 100%;
  overflow: auto;
  font-family: "Open Sans", Helvetica, sans-serif;
  background-color: var(--brand);
  color: var(--slate);
  line-height: 1.5;

  @include for-mobile {
    text-align: center;
  }
}



// =============================================================================
// Layout Elements
// =============================================================================

.main {
  width: 100vw;
  min-height: 100vh;
  padding-top: 84px;
  background-color: var(--white);
}
.header--sm ~ .main { padding-top: 12px; }

.section {
  width: 100%;
  &.section--full {
    min-height: calc(100vh - 84px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.header--sm ~ .main .section--full { min-height: calc(100vh - 12px); }

.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);

  &.tp--md { padding-top: var(--space-md); }
  &.tp--sm { padding-top: var(--space-sm); }

  &.bp--md { padding-bottom: var(--space-md); }
  &.bp--sm { padding-bottom: var(--space-sm); }

  &.lg { max-width: 1200px; }
}

.content {
  width: 100%;
  margin: 0 auto;

  &.content--center { text-align: center; }
  &.content--sm { max-width: 640px; }
}



// =============================================================================
// Content Components
// =============================================================================

.section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h1 { margin: 0 !important; }
  > .button {
    margin: 0 !important;

    @include for-mobile { margin-top: 24px !important; }
  }

  @include for-mobile {
    flex-direction: column;
  }
}

.section__heading {
  padding-bottom: 18px;
  margin-bottom: 60px;
  border-bottom: 1px solid var(--silver);
}


// =============================================================================
// ALERTS
// =============================================================================
.alert {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
  padding: 8px;
  background-color: #F8D7DA;
  border-radius: 4px;
  z-index: 100;
}

.success {
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
  padding: 8px;
  background-color: #D4EDDA;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.alert-success {
    margin: 12px auto 10px auto;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: #D4EDDA;
 }

.alert-danger {
    margin: 12px auto 10px auto;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    z-index: 100;
    background-color: #F8D7DA;
 }


// =============================================================================
// REACT MODAL
// =============================================================================

// Animation
.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}
 
// Header
.modal__header {
  display: flex;
  justify-content: space-between;

  &.close {
    cursor: pointer;
  }
}


.edit-form {
  .input--text {
    padding: 10px;
  }
}

.modal__footer {
  @include for-mobile {
    display: flex;
    justify-content: space-between;
  
    .button__sm {
      padding: 5px;
    }
  }
}


// =============================================================================
// REACT DELETE PANEL CONFIRMATION POP-UP
// =============================================================================

.confirmation__pop-up {
  text-align: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  padding: 30px;
}


.panel__info {
  @include for-mobile {
    margin-top: 16px;
  }
}


// =============================================================================
// USER CARD - ADMIN PANEL
// =============================================================================
.user__details {
  color: #DC9292;

  > span {
    //color: #4A4B4F;
    color: #9A9A9F;
  }
}

.user-details__status-active {
  color: #DC9292;

  > span {
    border-radius: 3px;
    padding: 4px 30px;
    color: #FAFAFA;
    background-color:#7fbb7f;
 }
}

.user-details__status-archived {
  color:#DC9292;

  > span {
    border-radius: 3px;
    padding: 4px 30px;
    color: #FAFAFA;
    background-color:#fc6874;
  }
}


// =============================================================================
// ADMIN DETAILS - ADMIN PANEL
// =============================================================================
.admin-panel__details {
  display: flex;
  justify-content: space-between;
  border: 1px solid #CACACF;
  padding: 20px;

  @include for-mobile {
    padding: 10px;
  }
}


