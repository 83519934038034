// =============================================================================
// Global Reset
// =============================================================================

* {
	position: relative;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--silver);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--silver);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--silver);
}
