// =============================================================================
// Colors
// =============================================================================

:root {
  --brand:#170C76;


  --slate: #4A4B4F;
  --gray: #9A9A9F;
  --silver: #CACACF;
  --snow: #FAFAFA;
  --white: #FFFFFF;
}



// =============================================================================
// Key Measurements
// =============================================================================

:root {
  --page-padding: 2rem;

  --space-md: 120px;
  --space-sm: 60px;
}
