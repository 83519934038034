// =============================================================================
// Responsive Breakpoints
// =============================================================================

@mixin for-desktop {
  @media (min-width: 601px) {
    @content;
  }
}

@mixin for-mobile {
  @media (max-width: 600px) {
    @content;
  }
}
