// =============================================================================
// Form Structure
// =============================================================================

form {
  margin: 24px auto;
}

.input__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  @include for-mobile {
    flex-direction: column;
  }
}

.input__group {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  flex-wrap: nowrap;
  margin: 0 -8px;

  > input {
    margin: 0 8px;

    @include for-mobile { margin: 0 4px; }
  }

  &.input__group--dob > input { max-width: 33.33%; }

  &.input__group--address > input {
    &.lg {  }
    &.sm { max-width: 33.33%; }
  }
}


// =============================================================================
// Input Fields
// =============================================================================

input.input--text {
  flex: 1;
  width: calc(100% - 180px);
  padding: 1rem;
  border: 1px solid var(--silver);
  border-radius: 8px;

  &--lg { 
    font-size: 1.5rem; 

    @include for-mobile {
      font-size: 1rem; 
    }
  }

  &:disabled { background-color: var(--snow); }

  @include for-mobile {
    width: 100%;
    text-align: center;
  }
}

select.input--text {
  flex: 1;
  width: calc(100% - 180px);
  padding: 1rem;
  border: 1px solid var(--silver);
  border-radius: 8px;

  &--lg { font-size: 1.5rem; }

  &:disabled { background-color: var(--snow); }

  @include for-mobile {
    width: 100%;
    text-align: center;
  }
}

label.input__label {
  display: inline-block;
  min-width: 180px;
  width: 180px;

  @include for-mobile {
    min-width: 0px;
    margin-bottom: 4px;
  }
}

input.error {
  border: 1.2px solid #d93025
}

.content--center input.input--text { text-align: center; }
