// =============================================================================
// Header Structure
// =============================================================================

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  padding: 0 0 0 var(--page-padding);
  background-color: var(--white);
  border-top: 12px solid var(--brand);
  border-bottom: 1px solid var(--silver);

  &.header--sm {
    height: 12px;
    border-bottom: none;
  }
}



// =============================================================================
// Navigation
// =============================================================================

.header__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header__left--auth {
  @include for-mobile {
    display: none;
  }
}

.header__right {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}


.header__placeholder {
    padding-right: 185px;

    @include for-mobile {
      display: none;
    }
}

.nav__home {
  display: flex;
  align-items: center;
  height: 100%;

  @include for-mobile {
    margin-right: -32px;
  }
}

.nav__home__visitor {
  display: flex;
  align-items: center;
  height: 100%;
  
  @include for-mobile {
    margin-right: 32px;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.breadcrumb {
  color: var(--gray) !important;
  a { text-decoration: none !important;}
  .fa-angle-left { margin-right: 10px }
  // ~ .breadcrumb:before {
  //   content: '/\00a0';
  //   padding: 0 8px;
  //   color: var(--silver);
  // }

  // a:link,
  // a:hover,
  // a:visited { color: var(--brand) !important; }
  // pointer-events: none;

  // &:last-child a:link,
  // &:last-child a:hover,
  // &:last-child a:visited {
  //   color: var(--gray) !important;
  //   text-decoration: none;
  //   pointer-events: none;
  // }
}

.nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--page-padding);
  font-size: 0.875rem;
  text-decoration: none;
  border-left: 1px solid var(--silver);
  white-space: nowrap;
  cursor: pointer;

  .link__icon { @include for-mobile { display: none; } }

  .link__icon:first-child { margin-right: 0.5rem; }
  .link__icon:last-child { margin-left: 0.5rem; }

  &.disabled {
    color: var(--silver);
    pointer-events: none;
    cursor: not-allowed;
  }

  @include for-mobile {
    padding: 0 calc(0.5 * var(--page-padding));
  }
}
