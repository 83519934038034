// =============================================================================
// Orders List
// =============================================================================

.orders {
  width: 100%;
  margin-top: 60px;
}

.orders__item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid var(--silver);
  border-radius: 8px;
  margin-top: 16px;

  @include for-mobile { flex-direction: column; }
}

.orders__graphic__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  border-right: 1px solid var(--silver);

  @include for-mobile {
    width: 100%;
    height: 180px;
    border-right: none;
    border-bottom: 1px solid var(--silver);
  }
}

.orders__graphic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &.panel1 { background-image: url('../../assets/img/thyroid.jpg'); }
  &.panel2 { background-image: url('../../assets/img/menopause.jpg'); }
  &.panel3 { background-image: url('../../assets/img/panel3.jpg'); }
}

.orders__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.orders__header {
  padding: 30px;
}

.orders__id {
  margin-top: 2px;
  font-size: 0.875rem;
  color: var(--gray);
}

.orders__details {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--silver);
}

.orders__detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 30px;
  border-right: 1px solid var(--silver);

  &:last-child { border-right: none; }

  @include for-mobile {
    padding: 16px;
    align-items: center;
  }
}

.orders__detail__date {
  font-size: 0.875rem;
}


// =============================================================================
// Order View
// =============================================================================

.order__wrapper {
  display: flex;
  margin-top: 80px;

  @include for-mobile {
    flex-direction: column;
    align-items: stretch;
  }
}

.order__pane {
  flex: 1;
  min-height: 700px;
  background-color: #EBECF0;

  @include for-mobile {
    min-height: 500px;
  }
}

.order__details {
  width: 300px;
  padding-left: 64px;

  > div {
    margin-bottom: 24px;

    &:last-child { margin-bottom: 0; }
  }

  @include for-mobile {
    width: 100%;
    padding-left: 0;
    margin-top: 24px;
  }
}




// =============================================================================
// PDF VIEW
// =============================================================================


.pdf-control-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @include for-mobile {
    padding: 10px;
  }
}


.zoom-wrapper {
 margin-left: 40px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 @include for-mobile {
   // margin-left: 0;
   // margin-top: 10px;
   display: none;
 }
}

.zoom-button {
 background-color: #ffffff;
 border-radius: 50%;
 width: 30px;
 height: 30px;
 margin-right: 5px;

 &.plus {
   margin-left: 11px;
 }
}

.pagination {
 display: flex;
 justify-content: center;
 align-items: center;

//  @include for-mobile {
//    display: inline;
//  }
}

.pagination-page-counter {
  margin: 0px 15px 0;
  
  @include for-mobile {
   margin: 0;
   padding: 15px;
 }
}

.pagination .pagination-button {
  background-color: #DC9292;
  color: #FFFFFF;
  margin: 15px 0 15px 15px;

 @include for-mobile {
   margin: 0; 
 }
}

.download-button-wrapper {
 margin: 0 0 0 15px;
 
 @include for-mobile {
   margin: 0;
   padding: 15px;
 }
}

.document {
 display: flex;
 justify-content: center;
 margin-bottom: 80px;

 @include for-mobile {
   margin: 0; 
 }
}