// =============================================================================
// Nude
// =============================================================================

a:link,
a:hover,
a:visited { color: var(--brand-color); }

.terms__link:hover {
  color: #DC9292
}

// =============================================================================
// Buttons
// =============================================================================

.button {
  display: inline-block;
  padding: 1rem 2.5rem;
  margin-top: 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.button--primary {
    background-color: var(--brand) !important;
    color: var(--white) !important;
    box-shadow: 0px 5px 15px -4px rgba(74,75,79,0.3);
  }

  &.button--secondary {
    color: var(--gray) !important;
    border: 1px solid var(--silver);
  }

  &:hover {
    transform: translateY(-1px);
  }

  &.button--endcap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    margin-top: 0;
    padding: 16px;
    margin-top: 0 !important;
    background-color: var(--brand);
    box-shadow: none;
    border-radius: 0px 8px 8px 0px;

    &:hover {
      transform: translateY(0px);
    }

    .link__icon { height: 20px; }
  }

  @include for-mobile {
    width: 100%;
    text-align: center;
  }

  &.kit--register__desktop {
    @include for-mobile {
     display: none;
    }
  }
  &.kit--register__mobile {
    display: none;
    @include for-mobile {
     display: block;
    }
  }
}



// =============================================================================
// Wrapper
// =============================================================================

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > .button {
    margin-left: 0.75rem;

    &:first-child { margin-left: 0; }
  }

  &.buttons--center {
    justify-content: center;

    > .button {
      margin-left: 0.375rem;
      margin-right: 0.375rem;
    }
  }
}


.custom {
  @include for-mobile {
    flex-direction: column
  }
  > .button {
    @include for-mobile {
      margin-left: 0;
      margin-right: 0;
    }
  }
}



// =============================================================================
// Link Icons
// =============================================================================

.link__icon {
  height: 0.875rem;
}

.button span ~ .link__icon { margin-left: 0.75rem; }
.button.submit--sm .link__icon { height: 1.25rem; }


// =============================================================================
// Button Small
// =============================================================================

.button__sm {
  display: inline-block;
  padding: 0.4rem 1.5rem;
  margin-top: 0.8rem;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.button__sm--primary {
    background-color: var(--brand) !important;
    color: var(--white) !important;
    box-shadow: 0px 5px 15px -4px rgba(74,75,79,0.3);
  }

  &.button__sm--secondary {
    color: var(--gray) !important;
    border: 1px solid var(--silver);
  }

  &:hover {
    transform: translateY(-1px);
  }

  &.button__sm--endcap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    margin-top: 0;
    padding: 16px;
    margin-top: 0 !important;
    background-color: var(--brand);
    box-shadow: none;
    border-radius: 0px 8px 8px 0px;

    &:hover {
      transform: translateY(0px);
    }

    .link__icon { height: 20px; }
  }

  &:first-child { margin-right: 10px; }

  @include for-mobile {
    width: 100%;
    text-align: center;
    &:first-child {margin-right: 0}
  }
}


