_// =============================================================================
// Menu Structure
// =============================================================================
.menu__item {
  display: block;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid var(--silver);
  color: var(--brand) !important;
  font-weight: 600;
  text-decoration: none;
}

.menu {
  position: fixed;
  top: 84px;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 300px;
  background-color: var(--white);
  border-left: 1px solid var(--silver);
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.open {
    transform: translateX(0%);
  }
}

.menu__item {
  display: block;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid var(--silver);
  color: var(--brand) !important;
  font-weight: 600;
  text-decoration: none;
}

// .menu {
//   position: fixed;
//   top: 84px;
//   right: 0;
//   bottom: 0;
//   z-index: 5;
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   justify-content: flex-start;
//   width: 300px;
//   background-color: var(--white);
//   border-left: 1px solid var(--silver);
//   transform: translateX(100%);
//   transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
// }

// .menu.open {
//   transform: translateX(0%);
// }

