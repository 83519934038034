// =============================================================================
// Brand Elements
// =============================================================================

img.brand {
  max-height: 48px;
  margin-bottom: 12px;
}

header img.brand {
  margin-bottom: 0;
  max-width: 85%;

  @include for-mobile { 
    max-height: 36px; 
    max-width: 70%;
  }
}
