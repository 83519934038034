// =============================================================================
// Base Font Size
// =============================================================================

:root {
  --basis: 16px;
}



// =============================================================================
// Text Elements
// =============================================================================

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.2;
  color: var(--brand);
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 0.75rem;
  color: var(--gray);
}

p {
  margin: 0 0 1.5rem;
}



// =============================================================================
// Utility
// =============================================================================

p.text--mute {
  color: var(--gray);
  font-size: 0.875rem;
}
